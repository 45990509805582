<template>
  <div class="lesson-homework" v-if="loading">
    <v-form ref="form" class="homework-content">
      <div class="content-block">
        <div class="block-title">
          <div class="box-title-text">Заголовок</div>
        </div>
        <div>
          <TextInput valid v-model="lesson.homeworkTitle" label="Текст заголовка" />
        </div>
      </div>
      <div class="content-block mb-4">
        <div class="block-title">
          <div class="box-title-text">Текстовый блок</div>
        </div>
        <div>
          <trumbowyg :config="config" v-model="lesson.homeworkDescription"></trumbowyg>
          <div class="d-flex flex-row justify-space-between">
            <v-btn @click="clearTags(['p', 'br', 'ol', 'ul', 'li', 'a'], lesson)" class="button-white-blue mt-3 mr-5 mb-4"
              depressed>
              <span>Очистить стили текста</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="content-block">
        <div class="block-title">
          <div class="box-title-text">Приложения</div>
        </div>
        <div>
          <div class="block-file" v-for="(file, index) in blockFiles" :key="index">
            <FileInput class="file-input" v-model="file.file" @change="addFile(file, $event)"
              acceptFiles=".doc,.docx,.pdf,.xls,.xlsx,.txt" label="Приложенный файл" />
            <div class="file-preview">
              <div v-if="!file.srcFile">
                <v-icon>mdi-paperclip</v-icon>
              </div>
              <div v-else @click="openFile(file.srcFile)">
                <v-icon color="var(--app-blue)">mdi-paperclip</v-icon>
              </div>
            </div>
          </div>

          <div class="d-flex flex-row justify-space-between">
            <v-btn @click="addNewFileBlock" class="button-white-blue" depressed>
              <span>Добавить файл</span>
            </v-btn>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import TextInput from "@/components/main/inputs/TextInput.vue";
import FileInput from "@/components/main/inputs/FileInput";
export default {
  components: {
    TextInput,
    FileInput,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    lesson: {},

    loading: false,
    config: {
      lang: "ru",
      btns: [
        ["strong", "em", "del"],
        ["unorderedList", "orderedList"],
        ["link"],
      ],
    },
    blockFiles: []
  }),
  methods: {
    updateItem() {
      this.lesson = JSON.parse(JSON.stringify(this.item));
      if (this.lesson.homework && this.lesson.homework.length > 0) {
        this.blockFiles = this.lesson.homework.map(i => {
          return {
            tempId: (~~(Math.random() * 1e8)).toString(16),
            file: i.file,
            srcFile: i.file,
          }
        })
      }
      else {
        this.blockFiles = [
          {
            tempId: (~~(Math.random() * 1e8)).toString(16),
            file: null,
            srcFile: null,
          },
        ]
      }

      this.loading = true;
      if (this.$refs.form) {

        this.$refs.form.resetValidation()
      }

    },
    clearTags(tagEnter, item) {
      let tags = [],
        tag = "";
      for (let a = 0; a < tagEnter.length; a++) {
        tag = tagEnter[a].replace(/<|>/g, "").trim();
        if (tagEnter[a] == "br") tags.push(tag);
        else if (tagEnter[a].length > 0) tags.push(tag, "/" + tag);
      }
      let re = new RegExp("<(?!(" + tags.join("|") + ")s*/?)[^>]+>", "g"); //remove all tags кроме заданных в tagEnter
      item.homeworkDescription = item.homeworkDescription.replace(re, "");
      item.homeworkDescription = item.homeworkDescription.replace(
        /\s*style=(["'])(.*?)\1/gim,
        ""
      ); //remove styles
      item.homeworkDescription = item.homeworkDescription.replace(
        /\s*class=(["'])(.*?)\1/gim,
        ""
      );
    },
    addFile(item, file) {
      if (file) {
        item.srcFile = URL.createObjectURL(file);
      } else {
        if (this.blockFiles.length > 1) {
          this.blockFiles = this.blockFiles.filter(i => i.tempId != item.tempId)
        }
        item.srcFile = null;
      }
    },
    openFile(file) {
      window.open(file, "_blank");
    },
    addNewFileBlock() {
      this.blockFiles.push({
        file: "",
        srcFile: null,
        tempId: (~~(Math.random() * 1e8)).toString(16),
      });
    },
    sendItem() {
      if (this.$refs.form.validate()) {

        this.lesson.homeworkFiles = this.blockFiles.map(i => {
          if(i.file){
            return i.file
          }
        
        }).filter(i=>i)

        return this.lesson;
      }
    },
  },
  created() {
    this.updateItem();
  },
  watch: {
    item() {
      this.updateItem();

    },
  },
};
</script>
<style lang="scss" scoped>
.lesson-homework {
  padding: 16px 64px;

  .homework-content {
    .content-block {
      min-width: 700px;
      max-width: 700px;
      padding-top: 16px;

      .block-title {
        margin-bottom: 16px;

        .box-title-text {
          color: var(--app-black);
          font-size: 22px;
          font-weight: 600;
        }
      }

      .block-file {
        display: flex;

        .file-input {
          width: 100%;
        }

        .file-preview {
          width: 64px;
          height: 45px;
          margin-left: 16px;
          border-radius: 8px;
          background: var(--app-white);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }

  //   overflow: auto;
}
</style>
